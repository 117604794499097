import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

const TopicCard = ({ title, link }) => {
  if (link && link.includes('http')) {
    return (
      <a href={link}>
        <div className='card'>
          <div className='card-content'>
            <div className='media'>
              <div className='media-content'>
                <div>Good</div>
                <h4 className='title is-size-5-desktop is-size-6-touch has-text-light'>{title}</h4>
              </div>
            </div>
          </div>
        </div>
      </a>
    )
  }

  return (
    <Link to={link}>
      <div className='card'>
        <div className='card-content'>
          <div className='media'>
            <div className='media-content'>
              <h4 className='title is-size-5-desktop is-size-6-touch has-text-light'>{title}</h4>
            </div>
          </div>
        </div>
      </div>
    </Link>
  )
}

TopicCard.propTypes = {
  title: PropTypes.string.isRequired,
  link: PropTypes.string,
}

TopicCard.defaultProps = {
  link: null,
}

export default TopicCard
